export const RUSSIAN_TRANSLATIONS = {
  logout: 'Выйти',
  switchTheme: 'Сменить тему',
  mainPage: 'Главная',
  license: {
    expiring: 'Дней до истечения лицензии:',
    expired: 'Срок действия лицензии истек',
    errorToast: {
      head: 'Лицензия истекла или невалидна',
      message:
        'Вам доступен только раздел лицензий в панели администратора для обновления сертификата или ключа активации',
    },
  },
}
